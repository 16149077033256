/* eslint-disable @typescript-eslint/no-explicit-any */
import { ACTION_DROPDOWN_OPTIONS } from '../../../pages/trips/Moves/constants/moves.constants';
import { getValue, getTrailerNames } from '../../../pages/trips/Moves/utils/moves.utils';
// import { Shipment } from '../../../redux/models/shipment.models';
import { createDropdownOptions } from '../../../utils/core.utils';
import { SelectInput } from '../../shared/Input';

const renderShipments = (shipmentsIds: string[], shipments: any) => shipmentsIds.map((id) => (
  <span
    key={id}
    className="trailerChanges__move-shipment"
  >
    {getValue(id, shipments, 'shipment_no')}
  </span>
));

interface Props {
  move: any;
  prop: any;
  shipments: any;
  fleet: any;
  onTrailerChangeInput: any;
  fleetLocations: any;
}

export default function TrailerChangeTableRow({
  move, prop, shipments, fleet, onTrailerChangeInput, fleetLocations,
}: Props) {
  // const fleetLocationDropdownOptions = getLocationOptions(fleetLocations, fleet);
  console.log(fleetLocations);
  const fleetDropdownOptions = createDropdownOptions(Object.values(fleet));
  console.log(move);
  console.log(shipments);
  if (prop === 'shipments') {
    return renderShipments(move.shipments, shipments);
  }

  if (prop === 'from') {
    return getTrailerNames(move.from, fleet);
  }

  if (prop === 'to') {
    return (
      <SelectInput
        multiple
        name="to"
        page="{page}"
        value={move.to}
        options={fleetDropdownOptions}
        shouldHaveMargin={false}
        handleChange={onTrailerChangeInput(move.position)}
        dataCy="move_trailer_to"
        labelText=""
      />
    );
  }

  if (prop === 'action') {
    return (
      <SelectInput
        name="action"
        page="trailerChanges"
        value={move.action}
        options={ACTION_DROPDOWN_OPTIONS}
        shouldHaveMargin={false}
        dataCy="trailerChanges_action"
        handleChange={onTrailerChangeInput(move.position)}
      />
    );
  }
  return move[prop];
}
