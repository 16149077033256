import { createSlice } from '@reduxjs/toolkit';
import { Api } from "../../../services/services"
import { getPostSearchBody } from "../settings.utils";
import { ORGANIZATION } from "../slices";
import {
  AppState,
  DispatchProps,
} from '../../models/state.models';
import Analytics from '../../../utils/analytics';
import { SettingsApi } from '../../../services/SettingsApi';
import { OrgState } from './organization.models';
import { User } from '../../models/user.models';
import { EntityContainer } from '../../models/core.models';

const SLICE_NAME = ORGANIZATION;

const initialState: OrgState = {
  users: [],
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setUsers(state, action) {
      state.users = action.payload;
    },
    resetUsers: () => initialState,
  },
});

export const {
  setUsers,
  resetUsers,
} = slice.actions;

export const loadOrgs = (orgCode: string) => {
  const filters = getPostSearchBody('name', orgCode);
  return Api.Organisation.find(filters);
};

export const loadIntegrations = (orgId: string) => {
  return Api.Integrations.orgIntegrations(orgId);
};

export const getOAuth2Url = (appId: string) => {
  return Api.Integrations.authorizeOAuth2(appId);
};

export const fetchUsers = (orgId: string) => async (dispatch: DispatchProps) => {
  try {
    const response = await SettingsApi.getUsers(orgId);
    dispatch(setUsers(response.data.data));
  } catch (error) {
    Analytics.capture(error);
  }
}

export const selectUsers = (state: AppState) => state[SLICE_NAME].users || [];
export const selectUserContainer = (state: AppState) => {
  const users = selectUsers(state);
  return users.reduce((store: EntityContainer<User>, user: User) => {
    return {
      ...store,
      [user.entity_id]: user,
    }
  }, {});
};

export const organisationReducer = slice.reducer;