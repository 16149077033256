import { DropdownOptionsModel } from "../../../components/shared/SelectInput.models";
import { EntityContainer } from "../../../redux/models/core.models";
import { capitalizeFirstLetter } from "../../../utils/core.utils";

export const SHIPMENT_TYPES: EntityContainer<DropdownOptionsModel> = {
  BULK: {
    value: 'bulk',
    label: 'Bulk',
  },
  EXPORT_CONTAINERS: {
    value: 'export-containers',
    label: 'Export Containers',
  },
  IMPORT_CONTAINERS: {
    value: 'import-containers',
    label: 'Import Containers',
  },
  REEFER_VAN: {
    value: 'reefer-van',
    label: 'Reefer & Van',
  },
  SOUTHBOUND: {
    value: 'southbound',
    label: 'Southbound',
  },
};

export const SHIPMENT_STATUS_TYPES = {
  REQUESTED: {
    value: 'requested',
    label: 'Requested',
  },
  BOOKED: {
    value: 'booked',
    label: 'Booked',
  },
  COMPLETED: {
    value: 'completed',
    label: 'Completed',
  },
  MISSING_INFO: {
    value: 'missing-info',
    label: 'Missing Info',
  },
  INVOICED: {
    value: 'invoiced',
    label: 'Invoiced',
  },
  TRAINING: {
    value: 'training',
    label: 'Training',
  },
};

export const CONTAINER_SIZE = {
  '20FT_STD': {
    value: '20ft-std',
    label: '20ft STD',
  },
  '20FT_HC': {
    value: '20ft-hc',
    label: '20ft HC',
  },
  '20FT_OT': {
    value: '20ft-ot',
    label: '20ft OT',
  },
  '20FT_RF': {
    value: '20ft-rf',
    label: '20ft RF',
  },
  '40FT_STD': {
    value: '40ft-std',
    label: '40ft STD',
  },
  '40FT_HC': {
    value: '40ft-hc',
    label: '40ft HC',
  },
  '40FT_OT': {
    value: '40ft-ot',
    label: '40ft OT',
  },
  '40FT_RF': {
    value: '40ft-rf',
    label: '40ft RF',
  },
  '40FT_FR': {
    value: '40ft-fr',
    label: '40ft FR',
  },
  '45FT_STD': {
    value: '45ft-std',
    label: '45ft STD',
  },
  '45FT_HC': {
    value: '45ft-hc',
    label: '45ft HC',
  },
  '45FT_FR': {
    value: '45ft-fr',
    label: '45ft FR',
  },
};

export enum ReeferSetting {
  On = 'on',
  Continuous = 'continuous',
  Dry = 'dry',
  Off = 'off',
  None = ''
}

export const REEFER_SETTINGS: EntityContainer<DropdownOptionsModel> = {
  on: {
    label: capitalizeFirstLetter(ReeferSetting.On),
    value: ReeferSetting.On,
  },
  continuous: {
    label: capitalizeFirstLetter(ReeferSetting.Continuous),
    value: ReeferSetting.Continuous,
  },
  dry: {
    label: capitalizeFirstLetter(ReeferSetting.Dry),
    value: ReeferSetting.Dry,
  },
  off: {
    label: capitalizeFirstLetter(ReeferSetting.Off),
    value: ReeferSetting.Off,
  },
  '': {
    label: 'n/a',
    value: ReeferSetting.None,
  },
};

export const reeferSettingOptions: DropdownOptionsModel[] = Object.values(REEFER_SETTINGS);
