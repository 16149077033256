import React from 'react';
import { useSelector } from 'react-redux';
import { AlertCircle } from 'react-feather';
import { Alert, Spinner } from 'react-bootstrap';

import { loadingTrailers, selectChangedTrailerIds } from '../../../redux/slices/trailer-changes/trailerChanges';
import { getValue } from './utils/moves.utils';
import { EntityContainer } from '../../../redux/models/core.models';
import { Fleet } from '../../../redux/models/settings.models';

const TRAILER_UPDATE_MESSAGE = ' appears to be on other trips. ';
const SAVE_MESSAGE = 'Would you like to ';

interface Props {
  name: string;
  handleChangeModals: () => void;
  fleet: EntityContainer<Fleet>;
}
export default function TrailerChangeAlert({
  name,
  handleChangeModals,
  fleet,
}: Props) {
  const isLoading = useSelector(loadingTrailers);
  const changedTrailers = useSelector(selectChangedTrailerIds);
  const getTrailerNameString = (trailerId: string) => (trailerId ? `${getValue(trailerId, fleet)}` : '');
  const trailerNames = Object.keys(changedTrailers).map(
    (id) => getTrailerNameString(id),
  ).join(' & ');

  return (
    <>
      {isLoading && <Spinner animation="border" variant="primary" size="sm" />}
      {!isLoading && (
      <Alert
        variant="primary"
        className={`alert-outline ${name}-alert`}
        data-cy={`${name}-alert`}
      >
        <div className={`alert-icon ${name}-btn`} onClick={handleChangeModals}>
          <AlertCircle />
        </div>
        <div className="alert-message">
          <strong>{trailerNames}</strong>
          {TRAILER_UPDATE_MESSAGE}
          {SAVE_MESSAGE}
          <Alert.Link data-cy={`${name}-alert__link`} onClick={handleChangeModals}>update them as well?</Alert.Link>
        </div>
      </Alert>
      )}
    </>
  );
}
