/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import './TrailerChangeModal.scss';

import { Button, Modal, Spinner } from 'react-bootstrap';
import { CollapseButton } from '../../shared/CollapseButton';

import TrailerChangeCard from './TrailerChangeCard';
import TrailerChangeMsg from './TrailerChangeMsg';

import { MODAL_TITLE } from './trailerChange.constants';
import { EntityContainer } from '../../../redux/models/core.models';
import { Fleet } from '../../../redux/models/settings.models';
import { TripDetails } from '../../../redux/models/trip.models';
import { MoveState } from '../../../redux/slices/trailer-changes/trailerChanges.models';
import Analytics from '../../../utils/analytics';
import { fetchTrailerChanges } from '../../../redux/slices/trailer-changes/trailerChanges';

const CANCEL_BUTTON = 'Cancel';
const SAVE_BUTTON = 'Save and Close';

const SAVE_MODAL_MESSAGE = 'Or click SAVE to apply no further changes';

const SAVE_MODAL_APPLY_CHANGES_BUTTON = 'Apply Changes';
const SAVE_MODAL_DISMISS_BUTTON = 'Dismiss';
const SAVE_MODAL_SAVE_BUTTON = 'Save Without Further Changes';

interface Props {
  name: string;
  tripId: string;
  moveState: EntityContainer<MoveState>; 
  showTrailerChangeModal: boolean;
  handleModalClose: () => void;
  fleet: EntityContainer<Fleet>;
  handleTrailerChangeSave: () => void;
  isSaving: boolean;
  fleetLocations: any;
}

export function TrailerChangeModal({
  name,
  tripId,
  moveState,
  showTrailerChangeModal,
  handleModalClose,
  fleet,
  handleTrailerChangeSave,
  isSaving,
  fleetLocations,
}: Props) {
  console.log('render TrailerChangeModal');
  const [shouldExpandAllItems, setShouldExpandAllItems] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [trips, setTrips] = useState<TripDetails[]>([]);
  const onUpdateTrip = (updatedTrip: TripDetails) => {
    const updatedTripsArr = trips.map((trip) => {
      if (trip.entity_id === updatedTrip.entity_id) {
        return updatedTrip;
      }
      return trip;
    });
    setTrips(updatedTripsArr);
  };
  useEffect(() => {
    const load = async (moves: EntityContainer<MoveState>, id: string) => {
      console.log('fetchTrailerChanges');
      try {
        setIsLoading(true);
        const response = await fetchTrailerChanges(moves);
        if (response.status === 200) {
          const data: TripDetails[] = response.data.data || [];
          const tripData = data.filter((trip) => trip.entity_id !== id);
          console.log(tripData);
          setTrips(tripData);
        }
      } catch (error) {
        Analytics.capture(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (moveState && tripId) load(moveState, tripId);
  }, [moveState, tripId]);
  return (
    <Modal
      show={showTrailerChangeModal}
      onHide={handleModalClose}
      backdrop="static"
      scrollable
      dialogClassName="large-modal"
      data-cy={`${name}-modal`}
    >
      <Modal.Header>
        <Modal.Title>{MODAL_TITLE}</Modal.Title>
        <div className={`${name}-collapse-btn-wrapper`}>
          <CollapseButton
            shouldExpandAllItems={shouldExpandAllItems}
            setShouldExpandAllItems={setShouldExpandAllItems}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <TrailerChangeMsg
          fleet={fleet}
          buttonName="Save and Close"
        />
        {isLoading && <Spinner animation="border" variant="primary" />}
        {!isLoading && trips && trips.filter(
          (trip) => trip.data.moves.length > 0,
        ).map((trip) => (
          <TrailerChangeCard
            name={name}
            key={trip.entity_id}
            shouldExpandAllItems={shouldExpandAllItems}
            trip={trip}
            fleetLocations={fleetLocations}
            onUpdateTrip={onUpdateTrip}
          />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          id={`${name}-cancel__btn`}
          variant="light"
          onClick={handleModalClose}
          data-cy={`${name}-cancel__btn`}
        >
          {CANCEL_BUTTON}
        </Button>
        <Button
          id={`${name}-saveAll__btn`}
          data-cy={`${name}-saveAll__btn`}
          disabled={isSaving}
          onClick={handleTrailerChangeSave}
        >
          {isSaving && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              style={{ marginRight: '4px' }}
            />
          )}
          {SAVE_BUTTON}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

interface TrailerChangeSaveModalProps {
  handleChangeModals: () => void;
  handleModalClose: () => void;
  showTrailerSaveModal: boolean;
  handleSave: () => void;
  fleet: EntityContainer<Fleet>;
  isSaving: boolean;
  shouldDisableSave: boolean;
}

export function TrailerChangeSaveModal({
  handleChangeModals,
  handleModalClose,
  showTrailerSaveModal,
  handleSave,
  fleet,
  isSaving,
  shouldDisableSave,
}: TrailerChangeSaveModalProps) {
  return (
    <Modal
      id="trailerChanges-saveModal"
      data-cy="trailerChanges-saveModal"
      show={showTrailerSaveModal}
      onHide={handleModalClose}
      size="lg"
      backdrop="static"
      scrollable
    >
      <Modal.Header>
        <Modal.Title>{MODAL_TITLE}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="trailer-change-message">
          <TrailerChangeMsg
            fleet={fleet}
            buttonName="Apply Changes"
          />
        </div>
        {SAVE_MODAL_MESSAGE}
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="trailerChanges-dismiss__btn"
          data-cy="trailerChanges-dismiss__btn"
          variant="light"
          onClick={handleModalClose}
        >
          {SAVE_MODAL_DISMISS_BUTTON}
        </Button>
        <Button
          id="trailerChanges-apply__btn"
          data-cy="trailerChanges-apply__btn"
          disabled={shouldDisableSave}
          onClick={handleChangeModals}
        >
          {SAVE_MODAL_APPLY_CHANGES_BUTTON}
        </Button>
        <Button
          id="trailerChanges-save__btn"
          data-cy="trailerChanges-save__btn"
          disabled={shouldDisableSave}
          onClick={handleSave}
        >
          {isSaving && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              style={{ marginRight: '4px' }}
            />
          )}
          {SAVE_MODAL_SAVE_BUTTON}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
