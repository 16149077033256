/* eslint-disable */
import { toast } from 'react-toastify';
import { createSlice } from '@reduxjs/toolkit';

import { Move } from '../../models/trip.models';
import TrailerChangeApi from '../../../services/TrailerChanges/TrailerChangeApi';
import { EntityContainer } from '../../models/core.models';
import { AppState } from '../../models/state.models';
import { TRAILER_CHANGES } from '../slices';
import { arraysAreEqual } from '../../../utils/core.utils';
import { TrailerChangeState, MoveState } from './trailerChanges.models';
import { TrailerStateHashTable } from './trailerChanges.utils';

/**
 * The purpose of this reducer is to see if trailers are changed on moves.
 * Trailers are often handed off between drivers on other trips without unloading shipments.
 * If a trailer containing these shipments is changed on one move it could be possible it needs changing on another trip / move
 * Shipments don't need to be tracked as they can be unloaded without impacting other trips.
 */

const initialState: TrailerChangeState = {
  moves: {},
  trips: {},
  areTripsWithTrailerLoading: false,
};

const SLICE_NAME = TRAILER_CHANGES;

const slice = createSlice({
  name: TRAILER_CHANGES,
  initialState,
  reducers: {
    setOriginalTrailers(state, action) {
      const { moveId, trailerIds } = action.payload;
      state.moves[moveId].original = trailerIds;
    },
    setCurrentTrailers(state, action) {
      // console.log('setCurrentTrailers');
      // const moves = action.payload || [];
      // const update = moves.reduce((store: EntityContainer<MoveState>, move: Move) => {
      //   const moveId = move.entity_id;
      //   const original = state.moves[moveId]?.original;
      //   const trailerIds = move.data.trailer_ids || [];
      //   const shipmentIds = move.data.shipment_ids || [];
      //   const oldmate: MoveState = {
      //     original,
      //     current: {
      //       shipment_ids: shipmentIds,
      //       trailer_ids: trailerIds
      //     }
      //   }
      //   return {
      //     ...store,
      //     [moveId]: oldmate
      //   }
      // }, {});
      // state.moves = {
      //   ...update
      // }
    },
    setTrailerState(state, action) {
      // state.moves = action.payload;
    },
    setTrips(state, action) {
      // const { moveId, trailerIds } = action.payload;
      // state.moves[moveId].current = trailerIds;
    },
    setAreTripsWithTrailerLoading(state, action) {
      // state.areTripsWithTrailerLoading = action.payload;
    },
    resetTrailerChanges: () => initialState,
  },
});

export const {
  setAreTripsWithTrailerLoading,
  setOriginalTrailers,
  setCurrentTrailers,
  setTrailerState,
  setTrips,
  resetTrailerChanges,
} = slice.actions;

const mapInitialTrailerState = (moves: Move[]) => {
  const container: EntityContainer<MoveState> = {}
  const moveState = moves.reduce((store, move) => {
    const trailerIds = move.data.trailer_ids || [];
    const shipmentIds = move.data.shipment_ids || [];
    return {
      ...store,
      [move.entity_id]: {
        original: {
          shipment_ids: shipmentIds,
          trailer_ids: trailerIds
        },
        current: {
          shipment_ids: [],
          trailer_ids: []
        },
      }
    }
  }, container);
  return moveState;
};

// removes duplicates between trailers changes API call
export const getTripsArray = (tripsObj: any) => (dispatch: any) => {
  const arr = Object.values(tripsObj).flat().filter(
    (trips: any, index: any, self: any) => index === self.findIndex((t: any) => t.entity_id === trips.entity_id),
  );
  dispatch(setTrips(arr));
};

export const fetchTrailerChanges = (moveState: EntityContainer<MoveState>) => {
  // pass in the original to find others like it
  const moves = Object.values(moveState).flatMap((move) => move.original);
  // get unique combos so the order doesn't matter
  const hash = new TrailerStateHashTable(moves).getFirst();
  const trailerIds = hash?.trailer_ids || [];
  const shipmentIds = hash?.shipment_ids || [];
  return TrailerChangeApi.getTrailerChangesTrips(
    trailerIds,
    shipmentIds,
  );
};

export const getTripsWithTrailerChange = (state: any, move: Move) => async (dispatch: any) => {
  console.log('getTripsWithTrailerChange');
  // dispatch(setAreTripsWithTrailerLoading(true));
  // try {
  //   const container = { ...state };
  //   console.log(container);
  //   const { data: trailerChange } = await TrailerChangeApi.getTrailerChangesTrips(
  //     move.data.trailer_ids || [],
  //     move.data.shipment_ids || [],
  //   );
  //   console.log(trailerChange);
    
  // } catch (e) {
  //   const errorMessage = "Couldn't complete trailer search. Please contact support if the problem persists";
  //   toast(errorMessage, { type: 'error' });
  // } finally {
  //   dispatch(setAreTripsWithTrailerLoading(false));
  // }
};

export const updateTrailerChanges = async (data: any) => TrailerChangeApi.updateTrailerChanges(data);

export const getInitialTrailers = (moves: Move[]) => (dispatch: any) => {
  // const trailerState = mapInitialTrailerState(moves);
  // dispatch(setTrailerState(trailerState));
};

export const updateTrailerState = (moves: Move[]) => (dispatch: any) => {
  // console.log('updateTrailerState');
  // const trailerState = mapInitialTrailerState(moves);
  // console.log(trailerState);
  // dispatch(setTrailerState(trailerState));
};

export const selectAllState = (state: AppState) => state[SLICE_NAME];
export const loadingTrailers = (state: any) => state[SLICE_NAME].areTripsWithTrailerLoading;
export const selectTripsArray = (state: any) => state[SLICE_NAME].tripsArray;
export const selectMoveState = (state: AppState) => state[SLICE_NAME].moves;
export const selectIsTrailersChanged = (state: AppState) => {
  const moves = selectMoveState(state);
  return Object.values(moves).some((move) => arraysAreEqual(move.original.trailer_ids, move.current.trailer_ids));
};
export const selectChangedTrailers = (state: AppState) => {
  const moves = state[SLICE_NAME].moves || {};
  return Object.values(moves).filter((move) => {
    const original = move.original.trailer_ids;
    const current = move.current.trailer_ids;
    return !arraysAreEqual(original, current);
  });
};
export const selectChangedTrailerIds = (state: AppState) => {
  const moves = state[SLICE_NAME].moves || {};
  return Object.values(moves).reduce((store: EntityContainer<string>, move: MoveState) => {
    const trailerIds = move.original.trailer_ids.reduce((stateStore: EntityContainer<string>, id: string) => {
      return {
        ...stateStore,
        [id]: id,
      }
    }, {});
    return {
      ...store,
      ...trailerIds,
    }
  }, {});
};

export const trailerChangesReducer = slice.reducer;
