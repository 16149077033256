import { useSelector } from 'react-redux';

import TrailerChangeAlert from './TrailerChangeAlert';
import {
  TrailerChangeSaveModal,
  TrailerChangeModal,
} from '../../../components/modals/TrailerChangeModal/TrailerChangeModal';

import { selectFleetObject } from '../../../redux/slices/settings';
import { selectMoveState } from '../../../redux/slices/trailer-changes/trailerChanges';
import { EntityContainer } from '../../../redux/models/core.models';
import { Fleet } from '../../../redux/models/settings.models';

const TRAILER_CHANGE_ID = 'trailerChanges';

interface Props {
  showTrailerSaveModal: boolean;
  showTrailerChangeModal: boolean;
  isSaving: boolean;
  shouldDisableSave: boolean;
  tripId: string;
  fleetLocations: EntityContainer<Fleet>;
  showTrailers: boolean;
  handleSave: () => void;
  handleModalClose: () => void;
  handleTrailerChangeSave: () => void;
  handleChangeModals: () => void;
}

export default function MoveFooter({
  showTrailerSaveModal,
  handleSave,
  showTrailerChangeModal,
  isSaving,
  shouldDisableSave,
  handleModalClose,
  handleTrailerChangeSave,
  handleChangeModals,
  tripId,
  fleetLocations,
  showTrailers,
}: Props) {
  const fleet = useSelector(selectFleetObject);
  const moveState = useSelector(selectMoveState);
  return (
    <>
      {showTrailers && (
        <TrailerChangeAlert
          name={TRAILER_CHANGE_ID}
          handleChangeModals={handleChangeModals}
          fleet={fleet}
        />
      )}
      {showTrailerChangeModal && (
        <TrailerChangeModal
          name={TRAILER_CHANGE_ID}
          tripId={tripId}
          moveState={moveState}
          showTrailerChangeModal={showTrailerChangeModal}
          handleModalClose={handleModalClose}
          fleet={fleet}
          handleTrailerChangeSave={handleTrailerChangeSave}
          isSaving={isSaving}
          fleetLocations={fleetLocations}
        />
      )}
      <TrailerChangeSaveModal
        showTrailerSaveModal={showTrailerSaveModal}
        handleChangeModals={handleChangeModals}
        handleSave={handleSave}
        fleet={fleet}
        handleModalClose={handleModalClose}
        shouldDisableSave={shouldDisableSave}
        isSaving={isSaving}
      />
    </>
  );
}
