/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';
import { getTrailerNames } from '../../../pages/trips/Moves/utils/moves.utils';
import { selectChangedTrailers } from '../../../redux/slices/trailer-changes/trailerChanges';
import { EntityContainer } from '../../../redux/models/core.models';
import { Fleet } from '../../../redux/models/settings.models';

interface Props {
  buttonName: string;
  fleet: EntityContainer<Fleet>;
}

export default function TrailerChangeMsg({
  buttonName, fleet,
}: Props) {
  const TRAILER_UPDATE_MESSAGE = `Click ${buttonName} to update:`;
  const moves = useSelector(selectChangedTrailers);
  return (
    <div>
      {TRAILER_UPDATE_MESSAGE}
      <ul>
        {Object.values(moves).map((move) => {
          const original = move.original.trailer_ids || [];
          const current = move.current.trailer_ids || [];
          const initialTrailer = getTrailerNames(original, fleet);
          const updatedTrailer = getTrailerNames(current, fleet);
          return (
            <li key={initialTrailer}>
              <strong>
                {`${initialTrailer} to ${updatedTrailer}`}
              </strong>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
